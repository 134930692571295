<template>
  <div id="app">
    <h3
      v-if="dataSourceView.length == 0"
      class="text-center bold"
    >
      Chưa có thời khóa biểu cho kỳ này!!!!!!
    </h3>
    <CustomSchedule
      v-if="dataSourceView.length > 0"
      :time-ground="['06:00', '22:00']"
      :week-ground="['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'Chủ nhật']"
      :color="['#FF5733','#4CAF50','#9B59B6','#3498DB','#F1C40F','#E74C3C', '#2C3E50']"
      :subject-ids="subjectIds"
      :task-detail="dataSourceView"
    />

  </div>
</template>

<script>
/* eslint-disable eqeqeq */

import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomSchedule from '@/views/study/custom_schedule/CustomSchedule.vue'
import '@core/scss/vue/libs/vue-good-table.scss'

export default {
  name: 'StudentSchedule',
  components: {
    CustomSchedule,
  },

  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 100,
        organizationId: '',
        courseId: '',
        courseSemesterId: '',
        studentId: '',
      },
      currentMainData: undefined,
      table: {
        dataRows: [],
      },
      dataSourceView: [],
      subjectIds: [],
      user: getUser(),
    }
  },
  computed: {
    ...mapGetters({
      resourceName: 'creditClass/resourceName',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
      programmeSubjects: 'dropdown/programmeSubjects',
      teachers: 'dropdown/teachers',
      classes: 'dropdown/classes',
      students: 'dropdown/studentClasses',
      turns: 'turn/turns',
      studentSchedule: 'schedule/schedulers',
      schedulers: 'schedule/schedulers',
    }),
    courseId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.courseId : null
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.filter.organizationId = this.user.orgId
      this.filter.studentId = this.user.studentId
      this.filter.courseId = this.courseId
      await Promise.all([
        this.getCourses({ studentId: this.user.studentId }),
        this.getCourseSemesters({ courseId: this.filter.courseId }),
        this.getTurns(),
      ])
      if (this.courseSemesters.length > 0) {
        this.filter.courseSemesterId = this.courseSemesters[0].value
      }
      if (this.turns.length > 0) {
        this.turns.forEach((turn, index) => {
          const item = {
            rowNum: turn.name,
            T2: '',
            T3: '',
            T4: '',
            T5: '',
            T6: '',
            T7: '',
            CN: '',
          }
          this.table.dataRows.push(item)
        })
      }
      await this.onSearchMainData()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCoursesByStudentId',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      getProgrammeSubjects: 'dropdown/getProgrammeSubjects',
      createPlanData: 'creditClass/createPlanData',
      getTeacher: 'dropdown/getTeachers',
      getClasses: 'dropdown/getClasses',
      getStudents: 'dropdown/getStudentClasses',
      getTurns: 'turn/getTurnsV2',
      getSchedulers: 'schedule/getSchedulersV3',
    }),

    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ courseId: this.filter.courseId })
    },

    async onSearchMainData() {
      this.table.dataRows = []
      await this.getSchedulers(this.filter)
      const result = []
      if (this.schedulers) {
        if (this.schedulers.length > 0) {
          this.schedulers.forEach(item => {
            if (!this.subjectIds.includes(item.subjectId)) {
              this.subjectIds.push(item.subjectId)
            }
            const days = item.groupDayIds
            const arDay = days.split(',')
            const turns = item.groupTurnIds
            const arTurnItem = turns.split('|')
            for (let i = 0; i < arTurnItem.length; i++) {
              const arTurn = arTurnItem[i].split(',')
              for (let j = 0; j < arTurn.length; j++) {
                const temp = {
                  turn: arTurn[j],
                  days: arDay[i],
                  subjectName: item.subjectName,
                  subjectId: item.subjectId,
                  room: item.roomName,

                }
                result.push(temp)
              }
            }
          })
        }
      }
      const dataView = []
      for (let i = 1; i <= 7; i++) {
        const item = []
        const tempArr = []
        const arrSubjectId = []

        result.forEach(dataItem => {
          if (i == dataItem.days) {
            if (!arrSubjectId.includes(dataItem.subjectId)) {
              arrSubjectId.push(dataItem.subjectId)
            }
          }
        })
        for (let k = 0; k < arrSubjectId.length; k++) {
          const tempArr1 = []
          result.forEach(dataItem1 => {
            if (i == dataItem1.days) {
              if (arrSubjectId[k] === dataItem1.subjectId) {
                tempArr1.push(dataItem1)
              }
            }
          })
          tempArr.push(tempArr1)
        }
        if (tempArr.length > 0) {
          tempArr.forEach(itemTurn => {
            const arrRoom = itemTurn.map(turn => turn.room)
            // xu ly khac room
            const distinctRoom = Array.from(new Set(arrRoom))
            const tempPlag = []
            for (let k = 0; k < distinctRoom.length; k++) {
              const temPlag1 = []
              itemTurn.forEach(itemNew => {
                if (itemNew.room === distinctRoom[k]) {
                  temPlag1.push(itemNew)
                }
              })
              tempPlag.push(temPlag1)
            }
            tempPlag.forEach(itemTurn1 => {
              // eslint-disable-next-line radix
              const turns = itemTurn1.map(object => parseInt(object.turn))
              const minTurn = Math.min(...turns)
              const maxTurn = Math.max(...turns)
              const timeMinTurn = this.turns.find(turn => turn.id === minTurn).startAt
              const timeMaxTurn = this.turns.find(turn => turn.id === maxTurn).endAt
              const temp = {}
              temp.dateStart = timeMinTurn
              temp.dateEnd = timeMaxTurn
              temp.title = `${itemTurn1[0].subjectName} - ${itemTurn1[0].room}`
              temp.detail = `${itemTurn1[0].subjectName} - ${itemTurn1[0].room}`
              temp.subjectId = itemTurn1[0].subjectId
              item.push(temp)
            })
          })
        }
        dataView.push(item)
      }
      this.dataSourceView = [...dataView]
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
